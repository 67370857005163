









































import Vue from 'vue'
import { mapGetters } from 'vuex'
import CreditLine from '../../../../../../store/models/CreditLine'
import Dispatching from '../../../../../../store/models/Dispatching'
import DispatchingFile from '../../../../../../store/models/DispatchingFile'
export default Vue.extend({
  data: function() {
    return {
      columns: [
        {
          label: 'N° du dispatching',
          field: 'reference',
          searchable: true
        },
        /* {
          label: 'Nom du dispatching',
          field: 'totalReceived',
          searchable: true
        }, */
        {
          label: 'Nombre de bénéficiaires',
          field: 'nbBeneficiaries'
        },
        {
          label: 'Date',
          field: 'createdAt',
          date: true
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    dispatchings(): Dispatching[] | [] {
      const dispatchings = Dispatching.query()
        .withAll()
        .where('creditLineId', this.$route.params.lineId)
        .where('state', 'completed')
        .where('productId', this.getCurrentProduct.id)
        .orderBy('timestamp', 'desc')
        .all()
      dispatchings.forEach(dispatching => {
        const dispatchingFile = DispatchingFile.find(
          dispatching.dispatchingFileId
        )
        if (dispatchingFile) {
          dispatching.dispatchingFileId = dispatchingFile.nbBeneficiaries.toString()
        }
      })
      return dispatchings
    },
    line() {
      return CreditLine.find(this.$route.params.lineId)
    }
  },

  methods: {
    onSeeDetails(dispatching: Dispatching) {
      this.$router.push({
        name: 'finances.refunds.consignations.dispatchingView',
        params: {
          dispatchingId: dispatching.id
        }
      })
    }
  },
  created(this: any) {
    if (!this.dispatchings.length) {
      Dispatching.api().fetch()
    }
  }
})
